import PropTypes from 'prop-types';
import cleanUrl from './canonicalUrlCleaner';

/**
 * Used for next/prev tags in dynamic SEO components.
 * Build the next and prev config objects for link tags from given URL.
 * The returned URLs will always have the domain "https://placeit.net".
 *
 * @param {string} url - The URL to build the next and prev config objects
 * @returns {Array} - The array with the objects for the next and prev objects.
 */

const paginationLinkTagBuilder = url => {
  if (!url) return [];

  const parsedUrl = new URL(url);
  const pageParam = parsedUrl.searchParams.get('page');
  let nextUrl;
  let prevUrl;

  if (pageParam) {
    nextUrl = url.replace(`page=${pageParam}`, `page=${Number(pageParam) + 1}`);

    if (Number(pageParam) - 1 > 1) {
      prevUrl = url.replace(`page=${pageParam}`, `page=${Number(pageParam) - 1}`);
    } else {
      prevUrl = url.replace(`page=${pageParam}`, '');
    }
  }

  return pageParam
    ? [
        {
          rel: 'next',
          href: cleanUrl(nextUrl),
        },
        {
          rel: 'prev',
          href: cleanUrl(prevUrl),
        },
      ]
    : [];
};

paginationLinkTagBuilder.propTypes = {
  url: PropTypes.string.isRequired,
};

export default paginationLinkTagBuilder;

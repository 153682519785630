import {useContext} from 'react';
import PropTypes from 'prop-types';
import {NextSeo, DefaultSeo} from 'next-seo';
import defaultJson from './SeoTagDefaultConfig.json';
import cleanUrl from '../../lib/utils/canonicalUrlCleaner';
import {isBrowser} from '../../lib/utils/common';
import {AlgoliaContext} from '../../context/AlgoliaContext';
import checkNoFollowNoIndex from '../../lib/utils/indexationSeoValidator';
import paginationLinkTagBuilder from '../../lib/utils/paginationLinkTagBuilder';

export default function SeoTag({config, type, pageType, pageCategory}) {
  const {serverUrl} = useContext(AlgoliaContext);
  // Prioritize use of dynamic canonical
  const pageConfig = {
    ...config,
    canonical: cleanUrl(serverUrl) || config.canonical,
    additionalLinkTags: [...paginationLinkTagBuilder(serverUrl)],
  };
  // Use the extracted function to check for noFollowNoIndex
  const noFollowNoIndex = checkNoFollowNoIndex(serverUrl);
  const initializeCustomPageMetadata = () => {
    if (isBrowser() && !window.customPageMetadata) {
      window.customPageMetadata = {};
    }
  };

  const addPageType = () => {
    if (isBrowser() && pageType !== 'none') {
      window.customPageMetadata.pageType = pageType;
    }
  };

  const addPageCategory = () => {
    if (isBrowser() && pageCategory !== 'none') {
      window.customPageMetadata.pageCategory = pageCategory;
    }
  };

  return (
    <>
      {initializeCustomPageMetadata()}
      {addPageType()}
      {addPageCategory()}
      {type === 'default' ? (
        <DefaultSeo {...pageConfig} />
      ) : (
        <NextSeo {...pageConfig} nofollow={noFollowNoIndex} noindex={noFollowNoIndex} />
      )}
    </>
  );
}

SeoTag.defaultProps = {
  className: null,
  options: {},
  config: defaultJson,
  type: 'tag',
  pageType: 'none',
  pageCategory: 'none',
};

SeoTag.propTypes = {
  className: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  config: PropTypes.shape({
    canonical: PropTypes.string,
  }),
  type: PropTypes.oneOf(['tag', 'default']),
  /**
   *  All the possible values allowed to be assigned to the 'page_type' property.
   *  If the 'none' value is chosen, then the 'page_type' property value will be
   *  the one defined in the Page Settings key/value metatag as 'pageType' on Plasmic
   *  if said value exists.
   */
  pageType: PropTypes.oneOf([
    'none',
    'Category Page',
    'CE Pages',
    'For You',
    'Home',
    'Hybrid Page',
    'Learn Page',
    'Main Search',
    'PDP',
    'Text Page',
  ]),
  /**
   *  All the possible values allowed to be assigned to the 'page_category' property.
   *  If the 'none' value is chosen, then the 'page_category' property value will be
   *  the one defined in the Page Settings key/value metatag as 'pageCategory' on Plasmic
   *  if said value exists.
   */
  pageCategory: PropTypes.oneOf([
    'none',
    'All',
    'Design Templates',
    'Logos',
    'main',
    'Mixed',
    'Mockups',
    'Videos',
  ]),
};

export const PlasmicData = {
  name: 'SeoTag',
  props: {
    config: {
      type: 'object',
      defaultValue: defaultJson,
    },
    type: {
      type: 'choice',
      options: ['default', 'tag'],
      defaultValue: 'tag',
    },
    pageType: {
      type: 'choice',
      options: [
        'none',
        'Category Page',
        'CE Pages',
        'For You',
        'Home',
        'Hybrid Page',
        'Learn Page',
        'Main Search',
        'PDP',
        'Text Page',
      ],
      defaultValue: 'none',
    },
    pageCategory: {
      type: 'choice',
      options: ['none', 'All', 'Design Templates', 'Logos', 'main', 'Mixed', 'Mockups', 'Videos'],
      defaultValue: 'none',
    },
  },
  importPath: './components/SEO/SeoTag',
  isDefaultExport: true,
};
